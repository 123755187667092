import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { AppContextProvider } from './contexts/AppContext';
import { PortalApiClient } from './client/PortalApiClient';

function App() {
  const content = useRoutes(router);
  const apiClient = new PortalApiClient(process.env.REACT_APP_PORTAL_API_HOST);
  console.log("customer-portal-process");
  console.log(process.env);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <AppContextProvider apiClient={apiClient}>
          {content}
        </AppContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
