
import React, { createContext, useContext } from 'react';
import { PortalApiClient } from 'src/client/PortalApiClient';

interface AppContextValue {
  apiClient: PortalApiClient;
}

const AppContext = createContext<AppContextValue | null>(null);

interface AppContextProviderProps {
  apiClient: PortalApiClient;
  children: React.ReactNode;
}

export function AppContextProvider({ apiClient, children }: AppContextProviderProps) {
  const contextValue: AppContextValue = { apiClient };
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within AppContextProvider');
  }
  return context;
}