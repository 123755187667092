import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Customer, Feature, Environment } from 'src/models/customer';

export interface GetCustomerResponse {
  customer: Customer;
  customer_features: Feature[];
}

export class PortalApiClient {
  private client: AxiosInstance;

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });

    // Add error interceptor to redirect to auth page if 401 is returned
    this.client.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: any) => {
        if (error.response && error.response.status === 401) {
          const authUrl = error.response.data.auth_url;
          if (authUrl) {
            window.location.href = authUrl;
          }
        }
        throw error;
      }
    );
  }

  async getCustomers(): Promise<Customer[]> {
    const response: AxiosResponse<Customer[]> = await this.client.get(
      '/portal/api/customers'
    );
    return response.data;
  }

  async getCustomer(ckey: string): Promise<GetCustomerResponse> {
    const response: AxiosResponse<GetCustomerResponse> = await this.client.get(
      `/portal/api/customers/${ckey}`
    );
    return response.data;
  }

  async setFeatureValue(
    ckey: string,
    featureId: number,
    env: Environment,
    value: any
  ): Promise<void> {
    await this.client.post(
      `/portal/api/customers/${ckey}/features/${featureId}`,
      {env, value}
    );
  }
}
